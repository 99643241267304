body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


#searchbar .lc-text-field{
  margin-bottom: 0px;
}


.lc-tab:hover{
  color: #0a4021;
}
 .lc-tab--selected {
   font-weight: 700;
   color: #319C60;
 }

.lc-tab:before{
  background-color: #319C60;
}

.lc-btn{
  border-color:#319C60;
}